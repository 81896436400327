const GOOGLE_BOOKS_API_KEY = process.env.REACT_APP_GOOGLE_BOOKS_API_KEY;

export const fetchGoogleBooks = async (query) => {
  const url = `https://www.googleapis.com/books/v1/volumes?q=${query}&key=${GOOGLE_BOOKS_API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  
  if (!data.items || data.items.length === 0) {
    return [];
  }
  
  return data.items.map(item => {
    const volumeInfo = item.volumeInfo || {};
    
    return {
      id: item.id,
      title: volumeInfo.title || 'Unknown Title',
      author: volumeInfo.authors ? volumeInfo.authors.join(', ') : 'Unknown Author',
      publishedDate: volumeInfo.publishedDate || 'Unknown Date',
      description: volumeInfo.description || '',
      pageCount: volumeInfo.pageCount || 0,
      categories: volumeInfo.categories || [],
      imageUrl: volumeInfo.imageLinks 
        ? volumeInfo.imageLinks.thumbnail 
        : 'https://via.placeholder.com/100x150.png?text=No+Image'
    };
  });
};

