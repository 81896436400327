import React, { useState, useEffect, useRef } from 'react';
import { fetchGoogleBooks } from '../utils/api';

const SearchBar = ({ handleAdd, isAdded }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  const searchContainerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
        setSearchQuery('');
        setSearchResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchContainerRef]);

  // Update dropdown position when it becomes visible
  useEffect(() => {
    if (isDropdownVisible && searchContainerRef.current) {
      const rect = searchContainerRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width
      });
    }
  }, [isDropdownVisible, searchResults]);

  const handleSearch = async (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      const query = typeof e.target.value === 'string' ? e.target.value : searchQuery;
      if (!query.trim()) return;
      
      setIsSearching(true);
      setSearchQuery(query);
      
      try {
        const results = await fetchGoogleBooks(query);
        setSearchResults(results);
        setIsDropdownVisible(true);
      } catch (error) {
        console.error('Error searching books:', error);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleAddItem = (item) => {
    handleAdd(item);
    setIsDropdownVisible(false);  // Hide the dropdown
    setSearchQuery('');           // Clear the search input
    setSearchResults([]);         // Clear the search results
  };

  return (
    <div className="relative" ref={searchContainerRef}>
      <div className="relative border border-gray-300 rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-gray-500 focus-within:border-gray-500 bg-white">
        <input
          ref={inputRef}
          type="text"
          placeholder="Search for books by title, author, or ISBN"
          className="w-full px-4 py-3 pr-12 outline-none text-gray-700"
          onKeyDown={handleSearch}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button 
          className="absolute right-0 top-0 h-full px-4 text-gray-500 hover:text-gray-700 transition-colors flex items-center justify-center"
          onClick={handleSearch}
          disabled={isSearching}
        >
          {isSearching ? (
            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
          )}
        </button>
      </div>
      
      {isDropdownVisible && searchResults && (
        <div 
          className="fixed shadow-lg rounded-lg bg-white overflow-hidden z-50"
          style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            width: `${dropdownPosition.width}px`,
            maxHeight: '400px',
            overflowY: 'auto'
          }}
        >
          {searchResults.length > 0 ? (
            <div className="p-3">
              <h3 className="text-sm font-medium text-gray-500 mb-2 px-2">Search Results</h3>
              <ul className="divide-y divide-gray-100">
                {searchResults.map((result) => (
                  <li key={result.id}>
                    <div className="flex items-center p-3 hover:bg-gray-50 rounded-lg">
                      <img 
                        src={result.imageUrl} 
                        alt={result.title} 
                        className="w-12 h-16 object-cover rounded shadow-sm mr-3 flex-shrink-0"
                      />
                      <div className="flex-grow min-w-0">
                        <h4 className="font-medium text-gray-900 truncate">{result.title}</h4>
                        {result.author && (
                          <p className="text-sm text-gray-500 truncate">{result.author}</p>
                        )}
                        {result.publishedDate && (
                          <p className="text-xs text-gray-400">{result.publishedDate}</p>
                        )}
                      </div>
                      <button 
                        className={`ml-2 px-3 py-1 rounded-full text-sm font-medium flex-shrink-0 ${
                          isAdded(result.id) 
                            ? 'bg-green-100 text-green-800 cursor-not-allowed' 
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        }`}
                        onClick={() => !isAdded(result.id) && handleAddItem(result)}
                        disabled={isAdded(result.id)}
                      >
                        {isAdded(result.id) ? 'Added' : 'Add'}
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="p-6 text-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-4 text-gray-600 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <p className="text-gray-700 font-medium mb-1">No books found</p>
              <p className="text-gray-500 text-sm">Try a different search term or check the spelling</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
