import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ChillTimeImage from '../assets/Chill-Time.png'; // Import the image

const HomePage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/collections');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <div className="py-4 max-w-6xl mx-auto px-4">
      {/* Centered image */}
      <div className="flex justify-center mb-6">
        <img 
          src={ChillTimeImage} 
          alt="Person relaxing with a book" 
          className="max-w-full h-auto max-h-80"
        />
      </div>

      {/* Main content */}
      <div className="text-center max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">Organize Your Reading Life</h1>
        <p className="text-gray-600 text-lg mb-6">
          Create and curate collections of books that matter to you. Gist helps you build your personal library, 
          track what you're reading, what you've read, and what you want to read next.
        </p>
        {currentUser ? (
          <button 
            onClick={handleGetStarted}
            className="bg-gray-800 text-white font-medium py-3 px-8 rounded-lg transition-colors hover:bg-gray-700 text-lg"
          >
            Start Your Collection
          </button>
        ) : (
          <button 
            onClick={handleSignIn}
            className="bg-gray-800 text-white font-medium py-3 px-8 rounded-lg transition-colors hover:bg-gray-700 text-lg"
          >
            Sign In to Get Started
          </button>
        )}
      </div>
    </div>
  );
};

export default HomePage; 