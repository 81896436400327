import React from 'react';
import ListSelector from './ListSelector';

const BookCollectionPage = ({ onSelectList }) => {
  return (
    <div className="py-8 max-w-6xl mx-auto px-4">
      <div className="text-center mb-10 pb-8">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto mb-4 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
        <h1 className="text-3xl font-bold mb-3 text-gray-800">Your Reading Collections</h1>
        <p className="max-w-lg mx-auto text-gray-600">
          Organize your books into meaningful collections. Track what you're reading, 
          what you want to read, and your all-time favorites.
        </p>
      </div>
      
      <div className="border border-gray-300 rounded-xl p-6 bg-gray-50">
        <ListSelector 
          mediaType="book" 
          onSelectList={onSelectList}
        />
      </div>
    </div>
  );
};

export default BookCollectionPage; 