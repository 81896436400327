import React, { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Header = ({ isMobileMenuVisible, setIsMobileMenuVisible }) => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const mobileMenuRef = useRef(null);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleCollectionsClick = () => {
    navigate('/collections');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileMenuRef, setIsMobileMenuVisible]);

  return (
    <div className="px-4 py-6">
      <header className="py-4 px-6 flex justify-between items-center max-w-6xl mx-auto">
        <div 
          className="flex items-center cursor-pointer" 
          onClick={handleHomeClick}
        >
          <h1 className="text-3xl font-bold text-gray-800" style={{ fontFamily: "'Merriweather', serif" }}>Gist</h1>
        </div>
        
        {currentUser ? (
          <>
            <div className="md:hidden relative">
              <button 
                className="p-2 rounded-md hover:bg-gray-100 transition-colors"
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
                aria-label="Menu"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              {isMobileMenuVisible && (
                <div 
                  ref={mobileMenuRef}
                  className="absolute right-0 mt-2 w-52 bg-white rounded-lg shadow-lg py-2 z-10"
                >
                  <button 
                    onClick={() => { handleCollectionsClick(); setIsMobileMenuVisible(false); }}
                    className="flex items-center w-full text-left px-4 py-3 hover:bg-gray-100"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                    </svg>
                    <span className="text-base">My Collections</span>
                  </button>
                  <button 
                    className="flex items-center w-full text-left px-4 py-3 text-red-600 hover:bg-gray-100"
                    onClick={() => { handleLogout(); setIsMobileMenuVisible(false); }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <span className="text-base">Logout</span>
                  </button>
                </div>
              )}
            </div>
            <div className="hidden md:flex space-x-3 items-center">
              <button 
                onClick={handleCollectionsClick}
                className="flex items-center px-5 py-2.5 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
                <span className="text-lg">My Collections</span>
              </button>
              <button 
                className="flex items-center px-5 py-2.5 text-gray-700 rounded-lg hover:bg-gray-100 transition-colors"
                onClick={handleLogout}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                <span className="text-lg">Logout</span>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="md:hidden relative">
              <button 
                className="p-2 rounded-md hover:bg-gray-100 transition-colors"
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
                aria-label="Menu"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              {isMobileMenuVisible && (
                <div 
                  ref={mobileMenuRef}
                  className="absolute right-0 mt-2 w-52 bg-white rounded-lg shadow-lg py-2 z-10"
                >
                  <button 
                    onClick={() => { handleSignIn(); setIsMobileMenuVisible(false); }}
                    className="flex items-center w-full text-left px-4 py-3 hover:bg-gray-100"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                    </svg>
                    <span className="text-base">Sign In</span>
                  </button>
                  <button 
                    onClick={() => { handleSignUp(); setIsMobileMenuVisible(false); }}
                    className="flex items-center w-full text-left px-4 py-3 hover:bg-gray-100"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                    </svg>
                    <span className="text-base">Sign Up</span>
                  </button>
                </div>
              )}
            </div>
            <div className="hidden md:flex space-x-3 items-center">
              <button 
                onClick={handleSignIn}
                className="flex items-center px-5 py-2.5 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                </svg>
                <span className="text-lg">Sign In</span>
              </button>
              <button 
                onClick={handleSignUp}
                className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors text-base"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                </svg>
                <span>Sign Up</span>
              </button>
            </div>
          </>
        )}
      </header>
    </div>
  );
};

export default Header;
