import React from 'react';

const MediaList = ({ items, onRemove, currentPage, itemsPerPage, setCurrentPage, sortOption }) => {
  const getSortedItems = () => {
    if (!items || items.length === 0) return [];

    switch (sortOption) {
      case 'title':
        return [...items].sort((a, b) => a.title.localeCompare(b.title));
      case 'recent':
      default:
        return [...items].sort((a, b) => {
          if (!a.addedDate || !b.addedDate) return 0;
          return new Date(b.addedDate) - new Date(a.addedDate);
        });
    }
  };

  const sortedItems = getSortedItems();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  if (sortedItems.length === 0) {
    return (
      <div className="text-center py-16 px-4">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto mb-4 text-gray-600 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
        <h3 className="text-xl font-medium mb-2">Your Collection is Empty</h3>
        <p className="text-gray-600 max-w-md mx-auto">
          Use the search bar above to find and add books to your collection. Start building your personal library today!
        </p>
      </div>
    );
  }

  return (
    <div>
      <ul className="space-y-2">
        {currentItems.map((item, index) => (
          <li key={item.id || index} className="transition-colors hover:bg-gray-50">
            <div className="p-4 sm:p-6 flex items-start">
              <img 
                src={item.imageUrl} 
                alt={item.title} 
                className="w-16 h-24 sm:w-20 sm:h-28 object-cover rounded shadow-sm mr-4 flex-shrink-0"
              />
              <div className="flex-grow min-w-0">
                <h3 className="font-medium text-lg text-gray-900 mb-1">{item.title}</h3>
                {item.author && (
                  <p className="text-gray-600 mb-1">{item.author}</p>
                )}
                {item.publishedDate && (
                  <p className="text-sm text-gray-500">Published: {item.publishedDate}</p>
                )}
                {item.pageCount > 0 && (
                  <p className="text-sm text-gray-500 mt-1">{item.pageCount} pages</p>
                )}
                {item.categories && item.categories.length > 0 && (
                  <div className="mt-2 flex flex-wrap gap-1">
                    {item.categories.slice(0, 3).map((category, i) => (
                      <span key={i} className="inline-block bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded">
                        {category}
                      </span>
                    ))}
                  </div>
                )}
            </div>
            <button 
                className="ml-4 p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full transition-colors flex-shrink-0"
                onClick={() => onRemove(indexOfFirstItem + index)}
                aria-label="Remove book"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            </button>
            </div>
          </li>
        ))}
      </ul>
      
      {totalPages > 1 && (
        <div className="flex items-center justify-between py-4 px-6">
        <button
            className={`p-2 rounded-md ${currentPage === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-100'}`}
          onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
        </button>
          
          <div className="text-sm text-gray-600">
            <span className="hidden sm:inline">Showing </span>
            <span className="font-medium">{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, sortedItems.length)}</span>
            <span className="hidden sm:inline"> of </span>
            <span className="sm:hidden">/</span>
            <span className="font-medium">{sortedItems.length}</span>
            <span className="hidden sm:inline"> books</span>
        </div>
          
        <button
            className={`p-2 rounded-md ${currentPage === totalPages ? 'text-gray-300 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-100'}`}
            onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
        </button>
      </div>
      )}
    </div>
  );
};

export default MediaList;
