import { db } from '../../firebase';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  arrayUnion, 
  arrayRemove, 
  collection,
  addDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp
} from 'firebase/firestore';

// Get all lists for a user
export const getUserLists = async (userId) => {
  try {
    const listsRef = collection(db, 'users', userId, 'lists');
    const querySnapshot = await getDocs(listsRef);
    
    const lists = [];
    querySnapshot.forEach((doc) => {
      lists.push({
        id: doc.id,
        ...doc.data(),
        // Convert Firestore timestamps to JS Date objects
        createdAt: doc.data().createdAt?.toDate(),
        // Ensure items is always an array
        items: doc.data().items || []
      });
    });
    
    return lists;
  } catch (error) {
    console.error("Error getting user lists:", error);
    throw error;
  }
};

// Get lists by media type
export const getListsByType = async (userId, type) => {
  try {
    const listsRef = collection(db, 'users', userId, 'lists');
    const q = query(listsRef, where("type", "==", type));
    const querySnapshot = await getDocs(q);
    
    const lists = [];
    querySnapshot.forEach((doc) => {
      lists.push({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        // Ensure items is always an array
        items: doc.data().items || []
      });
    });
    
    return lists;
  } catch (error) {
    console.error(`Error getting ${type} lists:`, error);
    throw error;
  }
};

// Create a new list
export const createList = async (userId, listData) => {
  try {
    const listsRef = collection(db, 'users', userId, 'lists');
    const newList = {
      ...listData,
      createdAt: serverTimestamp(),
      items: []
    };
    
    const docRef = await addDoc(listsRef, newList);
    return {
      id: docRef.id,
      ...newList,
      createdAt: new Date()
    };
  } catch (error) {
    console.error("Error creating list:", error);
    throw error;
  }
};

// Update list details (name, etc.)
export const updateList = async (userId, listId, updates) => {
  try {
    const listRef = doc(db, 'users', userId, 'lists', listId);
    await updateDoc(listRef, updates);
    return true;
  } catch (error) {
    console.error("Error updating list:", error);
    throw error;
  }
};

// Delete a list
export const deleteList = async (userId, listId) => {
  try {
    const listRef = doc(db, 'users', userId, 'lists', listId);
    await deleteDoc(listRef);
    return true;
  } catch (error) {
    console.error("Error deleting list:", error);
    throw error;
  }
};

// Delete all lists for a user
export const deleteAllLists = async (userId) => {
  try {
    console.log("Deleting all lists for user:", userId);
    const listsRef = collection(db, 'users', userId, 'lists');
    const querySnapshot = await getDocs(listsRef);
    
    const deletePromises = [];
    querySnapshot.forEach((doc) => {
      console.log(`Deleting list: ${doc.id}`);
      deletePromises.push(deleteDoc(doc.ref));
    });
    
    await Promise.all(deletePromises);
    console.log(`Deleted ${deletePromises.length} lists`);
    return true;
  } catch (error) {
    console.error("Error deleting all lists:", error);
    throw error;
  }
};

// Add an item to a list
export const addItemToList = async (userId, listId, item) => {
  try {
    const listRef = doc(db, 'users', userId, 'lists', listId);
    const newItem = { ...item, addedDate: new Date() };
    
    await updateDoc(listRef, {
      items: arrayUnion(newItem)
    });
    
    return newItem;
  } catch (error) {
    console.error("Error adding item to list:", error);
    throw error;
  }
};

// Remove an item from a list
export const removeItemFromList = async (userId, listId, item) => {
  try {
    const listRef = doc(db, 'users', userId, 'lists', listId);
    
    await updateDoc(listRef, {
      items: arrayRemove(item)
    });
    
    return true;
  } catch (error) {
    console.error("Error removing item from list:", error);
    throw error;
  }
};

// Check if user document exists, create if it doesn't
export const ensureUserExists = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    // If user document doesn't exist, create an empty one
    if (!userDoc.exists()) {
      console.log("Creating user document for new user:", userId);
      await setDoc(userRef, {}, { merge: true });
    }
    
    return true;
  } catch (error) {
    console.error("Error ensuring user exists:", error);
    throw error;
  }
}; 