import React, { useState } from 'react';
import { useList } from '../contexts/ListContext';
import SearchBar from './SearchBar';
import MediaList from './MediaList';

const ListDetail = ({ list, onBack }) => {
  const { addItem, removeItem } = useList();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [sortOption, setSortOption] = useState('recent');
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);

  const handleAdd = async (item) => {
    if (!list) return;
    console.log(`Adding item to collection ${list.id}:`, item);
    await addItem(list.id, item);
  };

  const handleRemove = async (index) => {
    if (!list) return;
    console.log(`Removing item at index ${index} from collection ${list.id}`);
    await removeItem(list.id, index);
  };

  const isAdded = (id) => {
    if (!list || !list.items) return false;
    return list.items.some(item => item.id === id);
  };

  return (
    <div className="py-8 max-w-6xl mx-auto px-4">
      {/* Header with back button */}
      <div className="flex items-center mb-8">
        <button 
          onClick={onBack}
          className="mr-4 p-2 rounded-full hover:bg-gray-100 transition-colors"
          aria-label="Go back"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </button>
        <div>
          <h1 className="text-2xl font-bold text-gray-800">{list?.name}</h1>
        </div>
      </div>

      {/* Search section */}
      <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Add Books to This Collection</h2>
          <SearchBar 
            searchType="book"
            handleAdd={handleAdd}
            isAdded={isAdded}
          />
        </div>
      </div>

      {/* Books list section */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="p-6 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Books in This Collection</h2>
          
          <div className="relative">
            <button 
              className="flex items-center px-3 py-2 rounded-md hover:bg-gray-100 transition-colors"
              onClick={() => setSortDropdownVisible(!sortDropdownVisible)}
            >
              <span className="mr-2">Sort</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
              </svg>
            </button>
            
            {sortDropdownVisible && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <div className="py-1">
                  <button 
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortOption === 'recent' ? 'bg-gray-100' : ''}`}
                    onClick={() => {
                      setSortOption('recent');
                      setSortDropdownVisible(false);
                    }}
                  >
                    Recently Added
                  </button>
                  <button 
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortOption === 'title' ? 'bg-gray-100' : ''}`}
                    onClick={() => {
                      setSortOption('title');
                      setSortDropdownVisible(false);
                    }}
                  >
                    Title (A-Z)
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        
        <MediaList 
          items={list?.items || []} 
          onRemove={handleRemove}
          sortOption={sortOption}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default ListDetail; 