import React, { useState, useEffect } from 'react';
import { useList } from '../contexts/ListContext';

const ListSelector = ({ onSelectList }) => {
  const { getListsOfType, addList, editList, removeList } = useList();
  const [lists, setLists] = useState([]);
  const [loadingLists, setLoadingLists] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingListId, setEditingListId] = useState(null);
  const [newListName, setNewListName] = useState('');
  const [selectedList, setSelectedList] = useState(null);
  const [error, setError] = useState('');

  // Load lists on component mount
  useEffect(() => {
    const loadLists = async () => {
      try {
        setLoadingLists(true);
        const bookLists = await getListsOfType('book');
        setLists(bookLists);
        setLoadingLists(false);
      } catch (err) {
        console.error("Error loading book lists:", err);
        setError("Failed to load your book collections");
        setLoadingLists(false);
      }
    };
    
    loadLists();
  }, [getListsOfType]);

  const handleCreateList = async () => {
    if (!newListName.trim()) {
      setError("Please enter a collection name");
      return;
    }
    
    try {
      setError('');
      const newList = await addList(newListName, 'book');
      setLists([...lists, newList]);
      setNewListName('');
      setIsCreating(false);
    } catch (err) {
      console.error("Error creating list:", err);
      setError("Failed to create collection");
    }
  };

  const handleUpdateList = async () => {
    if (!newListName.trim()) {
      setError("Please enter a collection name");
      return;
    }
    
    try {
      setError('');
      await editList(editingListId, { name: newListName });
      setLists(lists.map(list => 
        list.id === editingListId ? { ...list, name: newListName } : list
      ));
      setNewListName('');
      setIsEditing(false);
      setEditingListId(null);
    } catch (err) {
      console.error("Error updating list:", err);
      setError("Failed to update collection");
    }
  };

  const handleDeleteList = async (listId) => {
    if (window.confirm("Are you sure you want to delete this collection? This action cannot be undone.")) {
      try {
        await removeList(listId);
        setLists(lists.filter(list => list.id !== listId));
        if (selectedList && selectedList.id === listId) {
          setSelectedList(null);
        }
      } catch (err) {
        console.error("Error deleting list:", err);
        setError("Failed to delete collection");
      }
    }
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditingListId(null);
    setNewListName('');
    setError('');
  };

  const cancelCreating = () => {
    setIsCreating(false);
    setNewListName('');
    setError('');
  };

  const startEditing = (list) => {
    setIsEditing(true);
    setEditingListId(list.id);
    setNewListName(list.name);
    setIsCreating(false);
  };

  const handleListClick = (list) => {
    if (onSelectList) {
      onSelectList(list);
    } else {
      setSelectedList(list);
    }
  };
  
  if (loadingLists) {
    return (
      <div className="text-center py-8">
        <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-500 mb-2"></div>
        <p>Loading your collections...</p>
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-xl font-semibold">Your Book Collections</h2>
        {!isCreating && !isEditing && (
          <button 
            className="flex items-center px-4 py-2 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors border border-gray-300"
            onClick={() => {
              setIsCreating(true);
              setIsEditing(false);
              setNewListName('');
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            <span>New Collection</span>
          </button>
        )}
      </div>
      
      {isCreating && (
        <div className="bg-gray-50 p-4 rounded-lg mb-6 border border-gray-300">
          <h3 className="font-medium mb-2">Create New Collection</h3>
          <div className="flex">
            <input 
              type="text"
              placeholder="Collection name"
              className="flex-grow px-3 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              autoFocus
            />
            <button 
              className="bg-gray-700 hover:bg-gray-800 text-white px-4 py-2 transition-colors"
              onClick={handleCreateList}
            >
              Create
            </button>
            <button 
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-r-lg transition-colors"
              onClick={cancelCreating}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      
      {lists.length === 0 && !isCreating ? (
        <div className="text-center py-8 px-4 border border-dashed border-gray-300 rounded-xl bg-gray-50">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto mb-4 text-gray-600 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
          </svg>
          <h3 className="text-lg font-medium mb-2">No Collections Yet</h3>
          <p className="text-gray-600 mb-4">
            Create your first book collection to start organizing your reading life.
          </p>
          <button 
            className="flex items-center px-5 py-2.5 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors border border-gray-300 mx-auto"
            onClick={() => setIsCreating(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            <span>Create Your First Collection</span>
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          {lists.map((list) => (
            <div 
              key={list.id}
              className={`border rounded-lg overflow-hidden ${
                !isEditing || editingListId !== list.id 
                  ? 'border-gray-200 hover:border-gray-400' 
                  : 'border-transparent'
              }`}
            >
              {isEditing && editingListId === list.id ? (
                <div className="flex items-center p-4">
                  <input 
                    type="text"
                    className="flex-grow px-3 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    autoFocus
                  />
                  <button 
                    className="bg-gray-700 hover:bg-gray-800 text-white px-4 py-2 border border-gray-700 transition-colors"
                    onClick={handleUpdateList}
                  >
                    Save
                  </button>
                  <button 
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-r-lg border border-gray-300 transition-colors"
                    onClick={cancelEditing}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div 
                  className="p-4 cursor-pointer"
                  onClick={() => handleListClick(list)}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium text-lg">{list.name}</h3>
                      <p className="text-sm text-gray-500">
                        {list.items?.length || 0} book{list.items?.length !== 1 ? 's' : ''}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <button 
                        className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          startEditing(list);
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </button>
                      <button 
                        className="p-2 text-gray-500 hover:text-red-500 rounded-full hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteList(list.id);
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListSelector; 