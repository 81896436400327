import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ListProvider, useList } from './contexts/ListContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Signup from './components/Signup';
import Header from './components/Header';
import HomePage from './components/HomePage';
import BookCollectionPage from './components/MediaTypePage';
import ListDetail from './components/ListDetail';

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedList, setSelectedList, getListById } = useList();
  
  const showHeader = location.pathname !== '/login' && location.pathname !== '/signup';
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  // Load list from URL if needed
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'collections' && !selectedList) {
      const listId = pathParts[2];
      const loadList = async () => {
        try {
          const list = await getListById(listId);
          if (list) {
            setSelectedList(list);
          }
        } catch (error) {
          console.error("Error loading list from URL:", error);
        }
      };
      loadList();
    }
  }, [location.pathname, selectedList, getListById, setSelectedList]);

  // Simplified to only handle books
  const handleSelectList = (list) => {
    setSelectedList(list);
    // Navigate to the list detail page using React Router
    navigate(`/collections/${list.id}`);
  };

  const handleBackToLists = () => {
    setSelectedList(null);
    // Navigate back to the collections page
    navigate('/collections');
  };

  return (
    <div className="bg-gist-bg min-h-screen">
      {showHeader && (
        <Header 
          isMobileMenuVisible={isMobileMenuVisible} 
          setIsMobileMenuVisible={setIsMobileMenuVisible} 
        />
      )}
      
      <div className="max-w-4xl mx-auto px-4 pb-6">
        <Routes>
          <Route path="/" element={<HomePage />} />
          
          <Route path="/collections" element={
            <PrivateRoute>
              <BookCollectionPage 
                onSelectList={handleSelectList} 
              />
            </PrivateRoute>
          } />
          
          <Route path="/collections/:listId" element={
            <PrivateRoute>
              {selectedList ? (
                <ListDetail 
                  list={selectedList} 
                  onBack={handleBackToLists} 
                />
              ) : (
                <Navigate to="/collections" replace />
              )}
            </PrivateRoute>
          } />
          
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <ListProvider>
          <AppContent />
        </ListProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
