import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { 
  getUserLists, 
  getListsByType, 
  createList, 
  updateList, 
  deleteList, 
  addItemToList, 
  removeItemFromList,
  ensureUserExists
} from '../services/firebase/database';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const ListContext = createContext();

export function useList() {
  return useContext(ListContext);
}

export function ListProvider({ children }) {
  const { currentUser } = useAuth();
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Function to fetch lists - extracted to be reusable
  const fetchUserLists = useCallback(async () => {
    if (!currentUser) return [];
    
    try {
      const userLists = await getUserLists(currentUser.uid);
      console.log("Fetched user lists:", userLists);
      return userLists;
    } catch (err) {
      console.error("Error in fetchUserLists:", err);
      setError('Failed to load lists');
      return [];
    }
  }, [currentUser]);

  // Handle data loading
  useEffect(() => {
    async function loadData() {
      if (!currentUser) {
        setLists([]);
        setSelectedList(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError('');

        // Ensure user document exists
        await ensureUserExists(currentUser.uid);
        
        // Fetch existing lists
        console.log("Fetching lists for user");
        const userLists = await fetchUserLists();
        setLists(userLists);
        
        // Set the first list as selected if there's no selected list and lists exist
        if (userLists.length > 0 && !selectedList) {
          setSelectedList(userLists[0]);
        }
      } catch (err) {
        console.error("Error in loadData:", err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [currentUser, fetchUserLists, selectedList]);

  // Get lists by media type
  const getListsOfType = async (type) => {
    if (!currentUser) return [];
    
    try {
      console.log(`Getting lists of type: ${type}`);
      const typeLists = await getListsByType(currentUser.uid, type);
      console.log(`Found ${typeLists.length} lists of type ${type}:`, typeLists);
      return typeLists;
    } catch (err) {
      console.error(`Error getting ${type} lists:`, err);
      setError(`Failed to load ${type} lists`);
      return [];
    }
  };

  // Create a new list
  const addList = async (name, type) => {
    if (!currentUser) return null;
    
    try {
      const newList = await createList(currentUser.uid, { name, type });
      setLists([...lists, newList]);
      
      // If this is the first list, automatically select it
      if (lists.length === 0) {
        setSelectedList(newList);
      }
      
      return newList;
    } catch (err) {
      console.error("Error creating list:", err);
      setError('Failed to create list');
      return null;
    }
  };

  // Update a list
  const editList = async (listId, updates) => {
    if (!currentUser) return false;
    
    try {
      await updateList(currentUser.uid, listId, updates);
      
      // Update local state
      setLists(lists.map(list => 
        list.id === listId ? { ...list, ...updates } : list
      ));
      
      // Update selected list if it's the one being edited
      if (selectedList && selectedList.id === listId) {
        setSelectedList({ ...selectedList, ...updates });
      }
      
      return true;
    } catch (err) {
      console.error("Error updating list:", err);
      setError('Failed to update list');
      return false;
    }
  };

  // Delete a list
  const removeList = async (listId) => {
    if (!currentUser) return false;
    
    try {
      await deleteList(currentUser.uid, listId);
      
      // Update local state
      const updatedLists = lists.filter(list => list.id !== listId);
      setLists(updatedLists);
      
      // If the deleted list was selected, select another list
      if (selectedList && selectedList.id === listId) {
        setSelectedList(updatedLists.length > 0 ? updatedLists[0] : null);
      }
      
      return true;
    } catch (err) {
      console.error("Error deleting list:", err);
      setError('Failed to delete list');
      return false;
    }
  };

  // Add an item to a list
  const addItem = async (listId, item) => {
    if (!currentUser) return null;
    
    try {
      const newItem = await addItemToList(currentUser.uid, listId, item);
      
      // Update local state
      setLists(lists.map(list => {
        if (list.id === listId) {
          return {
            ...list,
            items: [...(list.items || []), newItem]
          };
        }
        return list;
      }));
      
      // Update selected list if it's the one being modified
      if (selectedList && selectedList.id === listId) {
        setSelectedList({
          ...selectedList,
          items: [...(selectedList.items || []), newItem]
        });
      }
      
      return newItem;
    } catch (err) {
      console.error("Error adding item to list:", err);
      setError('Failed to add item to list');
      return null;
    }
  };

  // Remove an item from a list
  const removeItem = async (listId, itemIndex) => {
    if (!currentUser) return false;
    
    try {
      // Find the list and item
      const list = lists.find(l => l.id === listId);
      if (!list || !list.items || itemIndex >= list.items.length) {
        return false;
      }
      
      const item = list.items[itemIndex];
      await removeItemFromList(currentUser.uid, listId, item);
      
      // Update local state
      setLists(lists.map(list => {
        if (list.id === listId) {
          const newItems = [...list.items];
          newItems.splice(itemIndex, 1);
          return { ...list, items: newItems };
        }
        return list;
      }));
      
      // Update selected list if it's the one being modified
      if (selectedList && selectedList.id === listId) {
        const newItems = [...selectedList.items];
        newItems.splice(itemIndex, 1);
        setSelectedList({ ...selectedList, items: newItems });
      }
      
      return true;
    } catch (err) {
      console.error("Error removing item from list:", err);
      setError('Failed to remove item from list');
      return false;
    }
  };

  // Get a list by ID
  const getListById = async (listId) => {
    if (!currentUser) return null;
    
    try {
      // First check if it's in the current lists
      const existingList = lists.find(list => list.id === listId);
      if (existingList) {
        return existingList;
      }
      
      // If not found in current lists, fetch it from the database
      const listRef = doc(db, 'users', currentUser.uid, 'lists', listId);
      const listDoc = await getDoc(listRef);
      
      if (listDoc.exists()) {
        const list = {
          id: listDoc.id,
          ...listDoc.data(),
          createdAt: listDoc.data().createdAt?.toDate(),
          items: listDoc.data().items || []
        };
        return list;
      }
      
      return null;
    } catch (err) {
      console.error("Error getting list by ID:", err);
      setError('Failed to load list');
      return null;
    }
  };

  const value = {
    lists,
    setLists,
    selectedList,
    setSelectedList,
    loading,
    error,
    getListsOfType,
    getListById,
    addList,
    editList,
    removeList,
    addItem,
    removeItem
  };

  return (
    <ListContext.Provider value={value}>
      {children}
    </ListContext.Provider>
  );
}

export default ListContext; 